require('./bootstrap');

document.addEventListener('livewire:load', () => {
    window.Livewire.onPageExpired((response, message) => {
        alert('Tato stránka je nevalidní,\n po kliknutí na "OK" bude obnovena.');
        window.location.reload();
    });
    window.Livewire.onError((statusCode, response) => {
        if (statusCode === 403 || statusCode === 401) {
            window.location.href = '/login';
        }
        return false;
    });
});

///* Alpine needs to be last as it blocks other stuff *///
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();
